 <template>

  <div>
    <div id="site-wrapper" v-if="user" class="site-wrapper panel panel-ad-campaigns-start-new">
      <!-- #header start -->
      <NavBar />
      <!-- #header end -->
      <!-- #wrapper-content start -->
      <div id="wrapper-content" class="wrapper-content pt-0 pb-0">
        <div class="page-wrapper d-flex flex-wrap flex-xl-nowrap">
          <LeftSideBar />
          <div class="page-container">
            <div class="container-fluid">
              <div class="page-content-wrapper d-flex flex-column">

                <div id="section-accordion">

                  <div class="row">
                    <div class="col-md-12">
                          <EditBusinessBranchStepper v-bind:partner-id="$route.params.partnerId" />
                    </div>
                  </div>
                </div>



                <div class="lh-1 mt-5">
                  <FooterBar />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- #wrapper-content end -->
    </div>


    <div v-else>
      <LoadingScreen />
    </div>

  </div>
</template>
<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
  import NavBar from "../Layouts/NavBar";
  import LeftSideBar from "../Layouts/LeftSideBar";
  import FooterBar from "../Layouts/FooterBar";
  import LoadingScreen from "../Layouts/LoadingScreen";
  import EditBusinessBranchStepper from "../Stepper/EditBusinessBranchStepper.vue";
  export default {
    name: "EditBusinessLocationStepper",

    components: {
      NavBar,
      LeftSideBar,
      FooterBar,
      EditBusinessBranchStepper,
      LoadingScreen
    },
    computed: {
      ...mapGetters("auth", ["user"])
    },

    methods: {
      ...mapActions("auth")
    },  
     mounted() {

    }
  };
</script>